import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {INavItem} from '../../interfaces';
import {CommonModule} from '../../../common';
import {MenuLogoComponent} from '../sidenav/menu-logo/menu-logo.component';

@Component({
  selector: 'app-simple-toolbar',
  standalone: true,
  templateUrl: './simple-toolbar.component.html',
  styleUrl: './simple-toolbar.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatToolbarModule, MatIconModule, MenuLogoComponent]
})
export class SimpleToolbarComponent {
  @Input() currentRoute?: INavItem;
}
